<template>
  <CoordinateManagementTemplate
    :images="images"
    :parentPlanPage="planPage"
    :parentCoordinateManagement="coordinateManagement"
    :parentClauseFlg="true"
    :temporaryId="temporaryId"
    :onComplete="onComplete"
    :onCancel="onCancel"
    :completeButtonText="$t('button.update')"
    :dialogTitle="$t('title.agency.confirmEditHighLight')"
    :completeText="$t('description.confirmEditHighLight')"
    type="edit"
  />
</template>

<script>
import CoordinateManagementTemplate from '@/pages/agency/templates/CoordinateManagementTemplate';
import { getPdfImage, getPdfBlobToB64 } from '@/lib/commonUtil';
import {
  getApplicationFormMaster,
  getCoordinateManagementList,
  putCoordinateManagement,
} from '@/apis/agency/applicationForms';
import { mapActions } from 'vuex';

export default {
  name: 'EditCoordinateManagement',
  components: {
    CoordinateManagementTemplate,
  },
  data() {
    return {
      images: [],
      planPage: null,
      coordinateManagementList: [],
      temporaryId: null,
      coordinateManagement: {},
    };
  },
  created() {
    this.setLoading(true);
  },
  async mounted() {
    // 申込書取得
    const applicationFormPdf = await getApplicationFormMaster(
      this.$route.params['applicationFormId']
    );
    const b64 = await getPdfBlobToB64(applicationFormPdf);

    this.$set(this, 'images', await getPdfImage(b64));

    // ハイライト情報取得
    const applicationFormInfo = await getCoordinateManagementList(
      this.$route.params['applicationFormId']
    );
    this.$set(
      this,
      'coordinateManagementList',
      applicationFormInfo.coordinateManagements
    );
    this.$set(
      this,
      'coordinateManagement',
      this.coordinateManagementList.find(
        coordinateManagement =>
          coordinateManagement.id ==
          this.$route.params['coordinateManagementId']
      )
    );

    const plan = this.coordinateManagementList.find(
      coordinateManagement => coordinateManagement.planFlg
    );
    this.$set(this, 'planPage', plan ? plan.screenPage : null);

    this.setLoading(false);
  },
  methods: {
    ...mapActions('ui', ['setLoading']),

    async onComplete(newCoordinateManagement) {
      // 座標管理マスタ更新
      const result = await putCoordinateManagement(
        this.$route.params['applicationFormId'],
        newCoordinateManagement
      ).catch(err => {
        console.log(err);
      });

      if (result)
        this.$router.push({
          path: '/application_form_list',
          query: {
            applicationFormId: this.$route.params['applicationFormId'],
          },
        });
    },
    onCancel() {
      this.$router.push({
        path: '/application_form_list',
        query: {
          applicationFormId: this.$route.params['applicationFormId'],
        },
      });
    },
  },
};
</script>
