var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CoordinateManagementTemplate", {
    attrs: {
      images: _vm.images,
      parentPlanPage: _vm.planPage,
      parentCoordinateManagement: _vm.coordinateManagement,
      parentClauseFlg: true,
      temporaryId: _vm.temporaryId,
      onComplete: _vm.onComplete,
      onCancel: _vm.onCancel,
      completeButtonText: _vm.$t("button.update"),
      dialogTitle: _vm.$t("title.agency.confirmEditHighLight"),
      completeText: _vm.$t("description.confirmEditHighLight"),
      type: "edit"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }